.btn-facebook {
  @include social-buttons-color($social-facebook);
}
.btn-whatsapp {
  @include social-buttons-color($social-whatsapp);
}

.btn-twitter {
  @include social-buttons-color($social-twitter);
}

.btn-pinterest {
  @include social-buttons-color($social-pinterest);
}

.btn-google {
  @include social-buttons-color($social-google);
}

.btn-linkedin {
  @include social-buttons-color($social-linkedin);
}

.btn-dribbble {
  @include social-buttons-color($social-dribbble);
}

.btn-github {
  @include social-buttons-color($social-github);
}

.btn-youtube {
  @include social-buttons-color($social-youtube);
}

.btn-instagram {
  @include social-buttons-color($social-instagram);
}

.btn-reddit {
  @include social-buttons-color($social-reddit);
}

.btn-tumblr {
  @include social-buttons-color($social-tumblr);
}

.label-facebook {
  @include social-label-color($social-facebook);
}

.label-twitter {
  @include social-label-color($social-twitter);
}

.label-pinterest {
  @include social-label-color($social-pinterest);
}

.label-google {
  @include social-label-color($social-google);
}

.label-linkedin {
  @include social-label-color($social-linkedin);
}

.label-dribbble {
  @include social-label-color($social-dribbble);
}

.label-github {
  @include social-label-color($social-github);
}

.label-youtube {
  @include social-label-color($social-youtube);
}

.label-instagram {
  @include social-label-color($social-instagram);
}

.label-reddit {
  @include social-label-color($social-reddit);
}

.label-tumblr {
  @include social-label-color($social-tumblr);
}

.icon-facebook {
  @include social-icon-color($social-facebook);
}

.icon-twitter {
  @include social-icon-color($social-twitter);
}

.icon-pinterest {
  @include social-icon-color($social-pinterest);
}

.icon-google {
  @include social-icon-color($social-google);
}

.icon-linkedin {
  @include social-icon-color($social-linkedin);
}

.icon-dribbble {
  @include social-icon-color($social-dribbble);
}

.icon-github {
  @include social-icon-color($social-github);
}

.icon-youtube {
  @include social-icon-color($social-youtube);
}

.icon-instagram {
  @include social-icon-color($social-instagram);
}

.icon-reddit {
  @include social-icon-color($social-reddit);
}

.icon-tumblr {
  @include social-icon-color($social-tumblr);
}
