/*
.body {
  scroll-padding-top: 65px; 
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}
.headroom--pinned {
  transform: translateY(0%);
}
.headroom--unpinned {
  transform: translateY(-100%);
}
*/

.headroom {
  transition: top 0.5s ease-in-out;
}
.headroom--pinned {
  position: fixed;
  top: 0;
  width: 100%;
}
.headroom--unpinned {
  top: -80px;
}
