/* Si la pantalla es 200px de ancho o mas*/
@media screen and (min-width: 200px) {
  .section-title {
    font-size: 2.4em;
  }
  .section-subtitle {
    font-size: 1.3em;
  }
  .section-body {
    font-size: 1.3em;
  }
  .muelita-font-size {
    font-size: 0em;
  }
  .h4 {
    font-size: 1.2em;
  }
}

/* Si la pantalla es 280px de ancho o mas*/
@media screen and (min-width: 280px) {
  .section-title {
    font-size: 2.4em;
  }
  .section-subtitle {
    font-size: 1.3em;
  }
  .section-body {
    font-size: 1.3em;
  }
  .muelita-font-size {
    font-size: 0em;
  }
  .h4 {
    font-size: 1.3em;
  }
}

/* Si la pantalla es 320px de ancho o mas*/
@media screen and (min-width: 320px) {
  .section-title {
    font-size: 2.4em;
  }
  .section-subtitle {
    font-size: 1.6em;
  }
  .section-body {
    font-size: 1.3em;
  }
  .muelita-font-size {
    font-size: 0em;
  }
  .h4 {
    font-size: 1.3em;
  }
}

/* Si la pantalla es 480px de ancho o mas*/
@media screen and (min-width: 480px) {
  .section-title {
    font-size: 2.6em;
  }
  .section-subtitle {
    font-size: 1.8em;
  }
  .section-body {
    font-size: 1.3em;
  }
  .muelita-font-size {
    font-size: 0em;
  }
  .h4 {
    font-size: 1.3em;
  }
}

/* Si la pantalla es 768px de ancho o mas*/
@media screen and (min-width: 768px) {
  .section-title {
    font-size: 2.6em;
  }
  .section-subtitle {
    font-size: 1.9em;
  }
  .section-body {
    font-size: 1.4em;
  }
  .muelita-font-size {
    font-size: 1.9em;
  }
  .h4 {
    font-size: 1.4em;
  }
}

/* Si la pantalla es 1024px de ancho o mas*/
@media screen and (min-width: 1024px) {
  .section-title {
    font-size: 2.8em;
  }
  .section-subtitle {
    font-size: 2em;
  }
  .section-body {
    font-size: 1.5em;
  }
  .muelita-font-size {
    font-size: 2em;
  }
  .h4 {
    font-size: 1.6em;
  }
}

/* Si la pantalla es 1200px de ancho o mas*/
@media screen and (min-width: 1200px) {
  .section-title {
    font-size: 3.6em;
  }
  .section-subtitle {
    font-size: 2.8em;
  }
  .section-body {
    font-size: 1.5em;
  }

  .muelita-font-size {
    font-size: 3.5em;
  }
  .h4 {
    font-size: 1.6em;
  }
}
