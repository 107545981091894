/* Si la pantalla es 200px de ancho o mas*/
@media screen and (min-width: 200px) {
  .card .card-body .card-title {
    font-size: 1.5em;
  }
  .card .card-body .card-text {
    font-size: 10px;
  }
}

/* Si la pantalla es 280px de ancho o mas*/
@media screen and (min-width: 280px) {
  .card .card-body .card-title {
    font-size: 1.5em;
  }
  .card .card-body .card-text {
    font-size: 13px;
  }
}

/* Si la pantalla es 320px de ancho o mas*/
@media screen and (min-width: 320px) {
  .card .card-body .card-title {
    font-size: 1.7em;
  }
  .card .card-body .card-text {
    font-size: 15px;
  }
}

/* Si la pantalla es 480px de ancho o mas*/
@media screen and (min-width: 480px) {
  .card .card-body .card-title {
    font-size: 1.9em;
  }
  .card .card-body .card-text {
    font-size: 17px;
  }
}

/* Si la pantalla es 768px de ancho o mas*/
@media screen and (min-width: 768px) {
  .card .card-body .card-title {
    font-size: 2em;
  }
  .card .card-body .card-text {
    font-size: 20px;
  }
}

/* Si la pantalla es 1024px de ancho o mas*/
@media screen and (min-width: 1024px) {
  .card .card-body .card-title {
    font-size: 2.2em;
  }
  .card .card-body .card-text {
    font-size: 20px;
  }
}

/* Si la pantalla es 1200px de ancho o mas*/
@media screen and (min-width: 1200px) {
  .card .card-body .card-title {
    font-size: 2.4em;
  }
  .card .card-body .card-text {
    font-size: 23px;
  }
}
