.rec .rec-slider-container {
  margin: 0;
}
.rec.rec-arrow {
  color: #ffffff;
  background-color: #89d0c9;
  border-color: #89d0c9;
}

/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}
