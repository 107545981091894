/*!

 =========================================================
 * Paper Kit React - v1.3.0 based on Paper Kit - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/paper-kit-2
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'paper-kit/variables';
@import 'paper-kit/mixins';

// Plugins CSS
@import 'paper-kit/plugins/plugin-bootstrap-switch';
@import 'paper-kit/plugins/plugin-nouislider';
@import 'paper-kit/plugins/plugin-datepicker';
@import 'paper-kit/plugins/plugin-bootstrap-select';
@import 'paper-kit/plugins/plugin-fileupload';
// Isometric cards plugin
@import 'paper-kit/plugins/plugin-isometricGrids';
@import 'paper-kit/plugins/plugin-perfect-scrollbar';
@import 'paper-kit/plugins/plugin-tagsinput';

// For gallery, using PhotoSwipe


// Core CSS
@import 'paper-kit/buttons';
@import 'paper-kit/social-buttons';

@import 'paper-kit/inputs';
@import 'paper-kit/checkbox-radio';
@import 'paper-kit/progress-bars';
@import 'paper-kit/badges';
@import 'paper-kit/pagination';
@import 'paper-kit/typography';
@import 'paper-kit/misc';
@import 'paper-kit/labels';

// components
@import 'paper-kit/nucleo-icons';
@import 'paper-kit/tabs-navs';
@import 'paper-kit/navbars';
@import 'paper-kit/dropdown';
@import 'paper-kit/alerts';
@import 'paper-kit/images';
@import 'paper-kit/tooltips-and-popovers';
@import 'paper-kit/modal';
@import 'paper-kit/icons';
@import 'paper-kit/carousel';
@import 'paper-kit/footers';

// Cards
@import 'paper-kit/cards';
@import 'paper-kit/cards/card-plain';
@import 'paper-kit/cards/card-register';
@import 'paper-kit/cards/card-profile';
@import 'paper-kit/cards/card-background';
@import 'paper-kit/cards/card-blog';
@import 'paper-kit/cards/card-contact';
@import 'paper-kit/cards/card-image';
@import 'paper-kit/cards/card-register';
@import 'paper-kit/cards/card-pricing';
@import 'paper-kit/cards/card-product';
@import 'paper-kit/cards/card-signup';
@import 'paper-kit/cards/card-testimonials';

// example pages and sections
@import 'paper-kit/examples';
@import 'paper-kit/sections';

//agregadas por mi
@import 'paper-kit/sections/blogs';
@import 'paper-kit/sections/features';
@import 'paper-kit/sections/headers';
@import 'paper-kit/sections/pricing';
@import 'paper-kit/sections/projects';
@import 'paper-kit/sections/teams';
@import 'paper-kit/sections/testimonials';

@import 'paper-kit/responsive';

// Nucleo Icons
@import "paper-kit/nucleo-icons";

// React Differences
@import 'react/react-differences.scss';
